const studio = {
    mainText: 'Uns geht es um Ideen – je origineller, desto besser. Wir bringen in jeden Job etwas Frisches und lieben es, die Grenzen unserer Vorstellungskraft auszureizen.',

    processHL: 'Unser Prozess für state-of-the-art Ergebnisse',

    understandHL: 'Verstehen',
    understandText: 'Durch tiefgehende Recherchen, Erfahrung und Kundengespräche bauen wir ein gemeinsames Verständnis auf, welches die Grundlage unserer Arbeit darstellt.',

    planHL: 'Planen',
    planText: 'Um reibungslose Abläufe zu gewährleisten, geht mit jedem Projekt eine durchdachte Planung einher. So liefern wir Kunden akkurate Angebote und einen realistischen Timetable.',

    buildHL: 'Umsetzen',
    buildText: 'Wir vereinen Innovation, Design, Technologie und Effizienz, um analoge und digitale Inhalte zu erstellen und unseren Klienten das bestmögliche Angebot zu liefern.',

    improveHL: 'Verbessern',
    improveText: 'Jede Idee hat Potenzial. Wir von stepone setzen uns mit Ihren Visionen auseinander und machen das Gute noch besser.',
}

export default studio;