import React from 'react';

import '../styles/studio.scss'

const CreativeProcess = ({title, children, illustration}) => {

    return (
        <div id="process-wrapper" className="flex flex-col md:flex-row width-48 py-8 md:items-center" data-aos="fade-in">
            <span className="mb-6">
                {illustration}
            </span>
            
            <div className="flex flex-col md:w-2/3 mx-4 md:ml-12 mr-8 md:justify-center">
                <h2 className="text-2xl mb-2 underline">{title}</h2>
                <p className="leading-relaxed">{children}</p>
            </div>
        </div>
    )
}

export default CreativeProcess;
