import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CreativeProcess from '../components/creativeProcess'
import studio from '../data/studio'
import Img from "gatsby-image"
import {graphql} from 'gatsby'

//import MagnetMouse from 'magnet-mouse';
//import { Transition } from 'react-transition-group';

import Pattern2 from '../svgs/Pattern2.inline.svg'
import Pattern3 from '../svgs/Pattern3.inline.svg'
import Pattern4 from '../svgs/Pattern4.inline.svg'
import Pattern7 from '../svgs/Pattern7.inline.svg'
import Pattern8 from '../svgs/Pattern8.inline.svg'

import '../styles/studio.scss'

const pattern1 = <Pattern4 className="w-32"/>
const pattern2 = <Pattern3 className="w-32"/>
const pattern3 = <Pattern8 className="w-32"/>
const pattern4 = <Pattern2 className="w-32"/>





const studioPage = ({data}) => {

    /*useEffect(() => {
        let mm = new MagnetMouse({
            magnet: {
              element: '.magnet'
            }
          });
          
          mm.init();
    })*/


    return ( 
        <Layout>
            <SEO title="Studio" />

            <div id="main-wrapper" data-aos="fade-up"  data-aos-duration="1000" data-aos-delay="400">
                <div id="title-section-wrapper" className="flex flex-col md:flex-row justify-center md:justify-between py-32 items-center">
                    <div id="picture-wrapper" className="relative hidden lg:w-1/3 lg:block xl:w-1/2 xl:mr-24">
                        <Img fluid={data.allContentfulAsset.edges[0].node.fluid}/>
                        <Pattern7 className="absolute pattern-picture z-10 w-1/3"/>
                    </div>
                    <p className="text-2xl md:text-3xl underline lg:w-1/2">
                        {studio.mainText}
                    </p>
                </div>

                <div id="process-section" className="flex flex-col relative bg-black py-32 text-white" data-aos="fade-in">
                    <div className="px-6 md:px-20 lg:px-32 xl:px-56">
                        <div id="Illustration" className="absolute top-0 right-0 w-40 hidden lg:inline-block" />
                        <h2 className="text-2xl md:text-3xl underline mb-12" data-aos="fade-in">{studio.processHL}</h2>
                        <div className="flex flex-col md:flex-row md:justify-between md:flex-wrap">
                            <CreativeProcess title={studio.understandHL} illustration={pattern1}>
                                {studio.understandText}
                            </CreativeProcess>
                            <CreativeProcess title={studio.planHL} illustration={pattern2}>
                                {studio.planText}
                            </CreativeProcess>
                            <CreativeProcess title={studio.buildHL} illustration={pattern3}>
                                {studio.buildText}
                            </CreativeProcess>
                            <CreativeProcess title={studio.improveHL} illustration={pattern4}>
                                {studio.improveText}
                            </CreativeProcess>
                        </div>
                    </div>
                </div>

                <div id="picture-section" className="flex flex-col py-32">
                    <h2 className="text-3xl md:text-3xl mb-12 lg:mb-20" data-aos="fade-in"> 
                        Wir können uns sehen lassen.
                    </h2>
                    <div className="flex flex-col md:flex-row">
                        <div id="picture" className="flex flex-col items-center md:mr-6 md:w-1/4 w-full mb-12 md:mb-0" data-aos="fade-in">
                            <div className="block w-full mb-6">
                                <Img fluid={data.allContentfulAsset.edges[1].node.fluid}/>
                            </div>
                            <p className="text-lg">Florian Mende</p>
                            <p className="text-soGray">Design &amp; Entwicklung</p>
                        </div>
                        <div id="picture" className="flex flex-col items-center md:ml-6 md:w-1/4 w-full" data-aos="fade-in">
                            <div className="block w-full mb-6">
                                <Img fluid={data.allContentfulAsset.edges[2].node.fluid}/>
                            </div>
                            <p className="text-lg">Vinzent Theuer</p>
                            <p className="text-soGray">Strategie &amp; Design</p>
                        </div>
                    </div>
                </div>


            </div>
            
        </Layout>
    )
}

export const query = graphql`
  {
    allContentfulAsset(sort: {fields: title}) {
      edges {
        node {
          id
          fluid(maxWidth: 1920) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
    }
  }
`

export default studioPage;